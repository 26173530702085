import Glide, { Autoplay } from "@glidejs/glide";
import { domReady } from "../../utils";

const slideGlide = () => {
  const glide = document.querySelector(".glideSlideshowNews");
  if (glide) {
    new Glide(".glideSlideshowNews", {
      type: "carousel",
      autoplay: 5000,
      hoverpause: false,
      perView: 1
    }).mount();
  }
};

export default domReady(slideGlide);
