$(document).ready(function() {
  let stateInput = document.getElementById("state_id");
  let cityInput = document.getElementById("city_id");
  let stateInputSecondary = document.getElementById("state_id_secondary");
  let cityInputSecondary = document.getElementById("city_id_secondary");

  if (stateInput && cityInput) {
    stateInput.addEventListener(
      "change",
      function() {
        clearCityInputs();
        $.get("/cities/" + this.value, {}, function(data) {
          let cities = mountCities(data);
          cityInput.innerHTML = cities;
        });
      },
      false
    );

    function clearCityInputs() {
      cityInput.innerHTML = "<option value=''>Selecione a Cidade</option>";
    }

    function mountCities(items) {
      let cities = "<option value=''>Selecione a Cidade</option>";
      items.map(item => {
        cities += "<option value='" + item.id + "'>" + item.name + "</option>";
      });

      return cities;
    }
  }

  if (stateInputSecondary && cityInputSecondary) {
    stateInputSecondary.addEventListener(
      "change",
      function() {
        clearCityInputsSecondary();
        $.get("/cities/" + this.value, {}, function(data) {
          let cities = mountCitiesSecondary(data);
          cityInputSecondary.innerHTML = cities;
        });
      },
      false
    );

    function clearCityInputsSecondary() {
      cityInputSecondary.innerHTML =
        "<option value=''>Selecione a Cidade</option>";
    }

    function mountCitiesSecondary(items) {
      let cities = "<option value=''>Selecione a Cidade</option>";
      items.map(item => {
        cities += "<option value='" + item.id + "'>" + item.name + "</option>";
      });

      return cities;
    }
  }
});
