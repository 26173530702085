require("slick-carousel/slick/slick");

$(document).ready(function() {
  $(".slider").slick({
    infinite: true,
    arrows: false,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1
  });

  $(".slider-flyer").slick({
    infinite: true,
    arrows: true,
    dots: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          infinite: true,
          autoplay: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          dots: true,
          autoplay: true,
          arrows: false
        }
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          // dots: true,
          autoplay: true,
          arrows: false
        }
      }
    ]
  });
});

//ticking machine
var percentTime;
var tick;
var time = 0.1;
var progressBarIndex = 0;

$(".progressBarContainer .progressBar").each(function(index) {
  var progress = "<div class='inProgress inProgress" + index + "'></div>";
  $(this).html(progress);
});

function startProgressbar() {
  resetProgressbar();
  percentTime = 0;
  tick = setInterval(interval, 10);
}

function interval() {
  if (
    $(
      '.slider .slick-track div[data-slick-index="' + progressBarIndex + '"]'
    ).attr("aria-hidden") === "true"
  ) {
    progressBarIndex = $('.slider .slick-track div[aria-hidden="false"]').data(
      "slickIndex"
    );
    startProgressbar();
  } else {
    percentTime += 1 / (time + 5);
    $(".inProgress" + progressBarIndex).css({
      width: percentTime + "%"
    });
    if (percentTime >= 100) {
      $(".single-item").slick("slickNext");
      progressBarIndex++;
      if (progressBarIndex > 2) {
        progressBarIndex = 0;
      }
      startProgressbar();
    }
  }
}

function resetProgressbar() {
  $(".inProgress").css({
    width: 0 + "%"
  });
  clearInterval(tick);
}
startProgressbar();
// End ticking machine

$(".item").click(function() {
  clearInterval(tick);
  var goToThisIndex = $(this)
    .find("span")
    .data("slickIndex");
  $(".single-item").slick("slickGoTo", goToThisIndex, false);
  startProgressbar();
});
