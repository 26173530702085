import compose from "lodash/fp/compose";
import { domReady } from "../../utils";

const morphSearch = () => {
  const header = document.querySelector("header[role=menubar]");
  const mask = document.querySelector(".searchOverlayMask");
  const searchTrigger = document.querySelector("#searchTrigger");
  const searchBar = document.querySelector("#topSearchForm");
  const searchInput = document.querySelector("#topSearchField");

  searchTrigger.addEventListener("click", () => {
    header.classList.add("animateOut");
    searchBar.classList.add("isShowed");
    mask.classList.add("isShowed");
    searchInput.focus();
  });

  mask.addEventListener("click", () => {
    header.classList.remove("animateOut");
    searchBar.classList.remove("isShowed");
    mask.classList.remove("isShowed");
    searchInput.blur();
  });

  document.addEventListener("keydown", event => {
    if (event.keyCode === 27) {
      header.classList.remove("animateOut");
      searchBar.classList.remove("isShowed");
      mask.classList.remove("isShowed");
      searchInput.blur();
    }
  });
};

const hideHeader = () => {
  let refOffset = 0;
  const headerWrapper = document.querySelector("header[role=menubar]");

  const handler = () => {
    const newOffset = window.scrollY || window.pageYOffset;

    if (newOffset > refOffset) {
      headerWrapper.classList.remove("animateIn");
      headerWrapper.classList.add("animateOut");
    } else {
      headerWrapper.classList.remove("animateOut");
      headerWrapper.classList.add("animateIn");
    }

    refOffset = newOffset;
  };

  window.addEventListener("scroll", handler, false);
};

const morphHeader = () => {
  const header = document.querySelector("header[role=menubar]");

  const brandHome = document.querySelector(".brandHome");
  const brandGeneral = document.querySelector(".brandGeneral");

  const scrollHandle = () => {
    if (getBodyScrollTop() > 90) {
      header.classList.remove("hasNoBg");
      header.classList.add("shadow-sm");
      brandHome.classList.remove("isFadeIn");
      brandHome.classList.add("isFadeOut");
      brandGeneral.classList.remove("isFadeOut");
      brandGeneral.classList.add("isFadeIn");
    } else {
      header.classList.add("hasNoBg");
      header.classList.remove("shadow-sm");
      brandHome.classList.remove("isFadeOut");
      brandHome.classList.add("isFadeIn");
      brandGeneral.classList.remove("isFadeIn");
      brandGeneral.classList.add("isFadeOut");
    }
  };

  if (brandHome !== null) {
    document.addEventListener("scroll", scrollHandle);
  }
};

export default domReady(compose(morphSearch, morphHeader));
