import Vue from 'vue';
import * as ActionCable from '@rails/actioncable';

const ActionCablePlugin = {
  install(Vue, options) {
    Vue.mixin({
      mounted() {
        Vue.prototype.$actionCable = ActionCable.createConsumer("/cable")
      }
    });
  }
};


Vue.use(ActionCablePlugin);

